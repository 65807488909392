<template>
  <div class="app-container">
    <el-row :gutter="20">
      <el-col :span="18">
        <el-row class="float">
          <el-button type="primary" icon="el-icon-plus" >添加</el-button>
          <el-button type="primary" icon="el-icon-plus" >导出</el-button>
        </el-row>
        <el-table
          id="out-table"
          ref="singleTable"
          stripe
          :data="userList"
          highlight-current-row
          style="width:100%;"
          border
        >
          <el-table-column align="left" label="权限名称">
            <template slot-scope="scope">{{ scope.row.permissionName }}</template>
          </el-table-column>
          <el-table-column align="left" label="权限描述">
            <template slot-scope="scope">{{ scope.row.permissionDescribe }}</template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <i class="el-icon-edit" ></i>
              <i class="el-icon-delete"></i>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>
<script>

export default {
  name: 'User',
  data () {
    return {
      userList: []
    }
  },
  created () {
    this.getUsers()
  },
  methods: {
    getUsers () {
      this.$axios.get('/api/Permission/GetPermission').then(res => {
        this.userList = res
        // console.log(res)
      })
    }
  }
}
</script>

<style scoped>
.float {
  float: right;
  margin-bottom: 20px;
}
.float > button {
  margin-left: 10px;
}
</style>
